<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="6"
        sm="4"
        md="3"
        v-for="(item, index) in items"
        :key="index">
        <v-img height="230" :src="getImgSrc(item.id)"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Picture',
  components: {
  },
  data: () => ({
    items: [
    ]
  }),
  methods: {
    getImgSrc (id) {
      return '/imgs/other/' + id + '.PNG'
    }
  }
}
</script>

<style lang="scss">

</style>
